import React from 'react';
import { useContext, useEffect } from 'react';
import { AppContext, AppContextProps } from '../store/context';
import Button from '@/components/shared/Button/Button';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';

import 'normalize.css';

const NotFoundPage: React.FC = () => {
  const { setCurrentPage } = useContext(AppContext) as AppContextProps;

  useEffect(() => {
    setCurrentPage('404');
    document.querySelector('.navbar')?.classList.add('animation-play');
  }, []);

  return (
    <main className="page page--404">
      <div className="page-404__wrapper">
        <h1 className="page-404__title">404!</h1>
        <p className="page-404__description">
          {' '}
          We are working on our awesome
          <br />
          new website, stay tuned!{' '}
        </p>
        <Button tag="link" href="/">
          Go Back Home
        </Button>
      </div>
    </main>
  );
};

export default NotFoundPage;

export const Head = () => (
  <SEO
    title="Loop3 Studio - 404"
    description="We are working on our awesome new website, stay tuned!"
  />
);
